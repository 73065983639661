import isDev from "@hoken/core/utilities/isDev";

type CustomerioObject = {
  [key: string]: string;
};

type IdentifyObject = CustomerioObject & { id: string };

declare global {
  interface Window {
    _cio: {
      page: () => void;
      track: (eventName: string, attributes?: CustomerioObject) => void;
      identify: (attributes: IdentifyObject) => void;
    };
  }
}

export const trackPageViewCustomerIO = () => {
  const isBrowser = typeof window !== "undefined";
  const hasCustomerIO = isBrowser ? !!window?._cio : false;

  if (isDev && hasCustomerIO) {
    console.log(
      "%c(ﾉ◕ヮ◕)ﾉ*:･ﾟ✧: Customer.io Page View TRIGGERED:",
      "color: #4033D0; background: #F3F2F8; font-size: 16px",
    );
  }

  if (hasCustomerIO) {
    // Documentation: https://customer.io/docs/sdk/web/events/#send-your-own-page-events
    // Intentionally leaving the page name empty, this enforces customer.io to use the current URL (window.location.href).
    try {
      window?._cio?.page();
    } catch (e) {
      console.error(e);
    }
  }
};

export const trackEventCustomerIO = (
  eventName: string,
  attributes?: CustomerioObject,
) => {
  const isBrowser = typeof window !== "undefined";
  const hasCustomerIO = isBrowser ? !!window?._cio : false;

  if (isDev && hasCustomerIO) {
    console.log(
      "%c(ﾉ◕ヮ◕)ﾉ*:･ﾟ✧: Customer.io Event TRIGGERED:",
      "color: #4033D0; background: #F3F2F8; font-size: 16px",
    );
  }

  if (hasCustomerIO) {
    // Documentation: https://customer.io/docs/sdk/web/events/#send-custom-events-with-track
    window?._cio?.track(eventName, attributes);
  }
};

export const identifyUserCustomerIO = (id: string, attributes?: CustomerioObject) => {
  const isBrowser = typeof window !== "undefined";
  const hasCustomerIO = isBrowser && id ? !!window?._cio : false;

  if (isDev && hasCustomerIO) {
    console.log(
      "%c(ﾉ◕ヮ◕)ﾉ*:･ﾟ✧: Customer.io user identified:",
      "color: #4033D0; background: #F3F2F8; font-size: 16px",
    );
  }

  if (hasCustomerIO && window?._cio) {
    // Documentation: https://customer.io/docs/journeys/attributes/#how-do-i-send-attribute-data
    window?._cio?.identify({ id, ...attributes });
  }
};
