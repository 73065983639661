export const MONTH_MAPPING = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const WEEKLY_MAPPING = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const TRACKING_PREVIOUS_ROUTE = "tracking-previous-route";

export const TRACKING_CURRENT_ROUTE = "tracking-current-route";

export const TRACKING_HISTORY = "tracking-history";

export const TRACKING_EMAIL_VERIFICATION_REDIRECT =
  "tracking-email-verification-redirect";

export const HEADER_OFFSET = 52;

// TODO: Configure Jest to accept environment and have this constant mirror NEXT_PUBLIC_API
export const JEST_BACKEND_API = undefined;

export const URL_ERROR_PARAM_KEY = "error-alert";

export const CONTENT_MAX_WIDTH = "1240px";

// TODO: Migrate Endpoints into constants
const NEXT_PUBLIC_API = process.env.NEXT_PUBLIC_API;

// https://dev-api.gohoken.com/docs#/Profile/me_update_user_listing_api_v1_me_listings__room_id__put
export const ME_LISTINGS = "/api/v1/me/listings";

export const ENDPOINT_DROPS = `${NEXT_PUBLIC_API}/api/v1/drops`;

export const ENDPOINT_FEATURED_DROPS = `${NEXT_PUBLIC_API}/api/v1/drops?featured=true `;
export const MAX_EVENT_ROUTE_INITIAL_EVENTS = 250;

export const MAX_SEARCH_RESULT_EVENTS = 35;

export const PURCHASE_FLOW_WIDGET_MAX_WIDTH = "468px";

// GTM Events

// Fire on successful purchase
export const HOKEN_FE_PURCHASE__SUCCESS = "hoken-fe-purchase--success";

// Fire on successful bid
export const HOKEN_FE_BID__SUCCESS = "hoken-fe-bid--success";

// Fire on successful bid
export const HOKEN_FE_BID_ACCEPTED__SUCCESS = "hoken-fe-bid-accepted--success";

// Fire on successful sign up
export const HOKEN_FE_SIGNUP__SUCCESS = "hoken-fe-signup--success";

// Fire on successful listing
export const HOKEN_FE_ROOM_LISTING__SUCCESS = "hoken-fe-room-listing--success";

export const GTM_SCRIPT_ENV_URL = `'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${process.env.NEXT_PUBLIC_GTM_AUTH}&gtm_preview=${process.env.NEXT_PUBLIC_GTM_PREVIEW}&gtm_cookies_win=x'`;
export const GTM_IFRAME_ENV_URL = `https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM_ID}&gtm_auth=${process.env.NEXT_PUBLIC_GTM_AUTH}&gtm_preview=${process.env.NEXT_PUBLIC_GTM_PREVIEW}&gtm_cookies_win=x`;
