import { CredentialsProviderId } from "../authentication/authentication.interfaces";

import { signIn } from "next-auth/react";
import { useSession } from "next-auth/react";

export const useAuthentication = () => {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const { data: session, status }: any = useSession({
    required: true,
    /* istanbul ignore next */
    onUnauthenticated() {
      getAnonymousToken();
    },
  });

  const isTypeUser = session?.type === "user";
  const isTypeAnonymous = session?.type === "anonymous";

  // TODO: Cover as part of end to end integration
  /* istanbul ignore next */
  const getAnonymousToken = async () => {
    const Anonymous: CredentialsProviderId = "anonymous";
    try {
      await signIn(Anonymous, { redirect: false });
    } catch (error) {
      console.log("Error: refresh token request failed: ", error);
    }
  };

  const isAuthenticated =
    status !== "loading" && status === "authenticated" && isTypeUser;

  return {
    session,
    status,
    error: false,
    isTypeUser,
    isTypeAnonymous,
    isAuthenticated,
  };
};
