import {
  TRACKING_CURRENT_ROUTE,
  TRACKING_HISTORY,
  TRACKING_PREVIOUS_ROUTE,
} from "@hoken/core";

/**
 * Sets the initial previous route and current route.
 * Invoke once at the root component.
 */
export const initializeTrackingNavigation = () => {
  const hasDocument = Boolean(document);

  if (hasDocument) {
    const currentRoute = window.location.pathname;

    window.sessionStorage.setItem(TRACKING_PREVIOUS_ROUTE, currentRoute);
    window.sessionStorage.setItem(TRACKING_CURRENT_ROUTE, currentRoute);
    window.sessionStorage.setItem(TRACKING_HISTORY, JSON.stringify([currentRoute]));
  }
};

/**
 * Track the previous and current route as a user navigates.
 * As a route changes set the current as the new previous route
 * and the new `pathname` as the new current route.
 *
 * @requires initializeTrackingNavigation
 */
export const trackNavigation = () => {
  const hasWindow = typeof window !== "undefined";

  if (hasWindow) {
    const previousRoute = window.sessionStorage.getItem(TRACKING_PREVIOUS_ROUTE);
    const nextPreviousRoute = window.sessionStorage.getItem(TRACKING_CURRENT_ROUTE);
    const trackingHistory = window.sessionStorage.getItem(TRACKING_HISTORY);
    const stringifyTrackingHistory = trackingHistory ? trackingHistory : "[]";
    const currentHistory = JSON.parse(stringifyTrackingHistory);
    const currentRoute = window.location.pathname;

    if (previousRoute && nextPreviousRoute) {
      window.sessionStorage.setItem(TRACKING_PREVIOUS_ROUTE, nextPreviousRoute);
      window.sessionStorage.setItem(TRACKING_CURRENT_ROUTE, currentRoute);
      window.sessionStorage.setItem(
        TRACKING_HISTORY,
        JSON.stringify([...currentHistory, currentRoute]),
      );
    }
  }
};
