import isDev from "@hoken/core/utilities/isDev";

import { Options } from "./fetcher.interfaces";

/**
 * Configure fetch headers depending on bearer token
 * @returns valid request headers
 */
const setOptions = (options: Options & { "x-guest-id"?: string }): RequestInit => {
  const hasToken = options?.token;
  const hasGuestId = options?.["x-guest-id"];
  const isValidGuestId = typeof hasGuestId === "string" && hasGuestId.trim() !== "";

  return hasToken
    ? {
        headers: new Headers({
          "Content-Type": "application/json",
          "Accept-Language": "en-US",
          Authorization: `Bearer ${options?.token}`,
          ...(isValidGuestId && { "x-guest-id": hasGuestId }),
        }),
        ...options,
      }
    : {
        headers: new Headers({
          "Content-Type": "application/json",
          "Accept-Language": "en-US",
          ...(isValidGuestId && { "x-guest-id": hasGuestId }),
        }),
        ...options,
      };
};

export const fetcher = async (
  request: { endpoint: string; token?: string },
  additionalOptions: RequestInit & {
    "x-guest-id"?: string;
  } = {},
) => {
  try {
    const endpoint = request?.endpoint;
    const token = request?.token;
    const options: RequestInit = setOptions({
      token: token,
      ...additionalOptions,
    });
    const response = await fetch(endpoint, options);
    const ok = response.ok;
    const status = response.status;
    const data = await response.json();

    // TODO: Extract to utility function and confirm with BE on a more deterministic check
    const invalidTokenText =
      typeof data?.detail === "string" && data?.detail?.toLowerCase();
    const invalidToken =
      status === 401 && invalidTokenText === "Token has expired".toLowerCase();

    if (invalidToken) {
      throw {
        status,
        invalidTokenText,
        forceTokenRefresh: true,
      };
    }

    if (!ok) {
      /* istanbul ignore next */
      if (isDev) {
        console.log("Invalid Response", response);
      }
      throw {
        ok,
        status,
        data,
      };
    }

    return { data, ok, status };
  } catch (error) {
    if (isDev) {
      console.log("Error from fetcher:", error);
      console.log(
        "%c(ﾉ◕ヮ◕)ﾉ*:･ﾟ✧: Error from fetcher:",
        "color: #E72525; background: #F3F2F8; font-size: 16px",
      );
    }
    // Continue throwing error to propagate to invoker
    throw error;
  }
};
