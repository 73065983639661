import { useRef } from "react";

import { fetcher } from "@hoken/core";

import { useAuthentication } from "../../hooks/useAuthentication";
import { CredentialsProviderId } from "../authentication.interfaces";
import { NextAuthenticationManagerProps } from "./next-authentication-manager.interfaces";

import { signIn, signOut } from "next-auth/react";
import { Middleware, SWRConfig } from "swr";

/**
 * Middleware to suppress the error response from expired tokens from surfacing to the consuming useSWR client hook.
 * This does not prevent other error responses from surfacing such as if a refresh fails. It also does not prevent the
 * invocation of the SWRConfig onError callback that invokes the refresh flow.
 *
 * https://swr.vercel.app/docs/middleware
 */
const suppressTokenRefreshError: Middleware = (useSWRNext) => {
  return (key, fetcher, config) => {
    const swr = useSWRNext(key, fetcher, config);

    const isTokenRefreshError =
      swr?.error?.status === 401 && swr?.error?.invalidTokenText === "token has expired";

    if (isTokenRefreshError) {
      console.log("caught refresh error in usrSWR middleware");
      return Object.assign({}, swr, {
        error: undefined,
      });
    }

    return swr;
  };
};

export const NextAuthenticationManager = ({
  children,
  fallback,
}: NextAuthenticationManagerProps): JSX.Element => {
  const lockRefreshContainer = useRef<boolean>(false);
  const setFallback = fallback ? fallback : {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { session }: any = useAuthentication();
  const type = session?.type;
  const token = session?.token;
  return (
    <SWRConfig
      value={{
        fetcher,
        fallback: setFallback,
        onError: async (error) => {
          const forceRefresh = error?.forceTokenRefresh;
          const lockedRefresh = lockRefreshContainer.current;

          if (forceRefresh && !lockedRefresh) {
            try {
              lockRefreshContainer.current = true;
              const ForceTokenRefresh: CredentialsProviderId =
                "hoken-force-refresh-token";
              await signIn(ForceTokenRefresh, { type, token: token, redirect: false });
              lockRefreshContainer.current = false;
            } catch (error) {
              console.log("Refresh Error", error);
              await signOut({ redirect: true, callbackUrl: "/" });
              lockRefreshContainer.current = false;
            }
          }
        },
        use: [suppressTokenRefreshError],
      }}
    >
      {children}
    </SWRConfig>
  );
};
