import { useEffect } from "react";

import {
  GTM_SCRIPT_ENV_URL,
  NextAuthenticationManager,
  initializeTrackingNavigation,
  trackNavigation,
  trackPageViewCustomerIO,
  useRouter,
} from "@hoken/core";
import "@hoken/core/styles/tailwind.css";

import { Analytics } from "@vercel/analytics/react";
import { SessionProvider } from "next-auth/react";
import { AppProps } from "next/app";
import Head from "next/head";
import Script from "next/script";
import { useCookies } from "react-cookie";

const App = ({
  Component,
  pageProps: { session, fallback, canonical, seo, ...pageProps },
}: AppProps) => {
  const router = useRouter();
  const [_, setCookie] = useCookies(["cookies-consent"]);

  useEffect(() => {
    initializeTrackingNavigation();
  }, []);

  useEffect(() => {
    trackNavigation();
  }, [router.asPath]);

  // "routeChangeComplete" event is fired when the route transition is complete.
  // In local development, this effect might be fired twice due to how React fires effects in strict mode.
  useEffect(() => {
    router.events.on("routeChangeComplete", () => {
      trackPageViewCustomerIO();
    });
  }, [router.events]);

  //listening for the injection of the GDPR Unbounce banner
  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((node) => {
          if (node.nodeType === Node.ELEMENT_NODE) {
            const element = node as Element;
            if (element.matches(".ub-emb-container")) {
              const button = document.querySelector(".ub-emb-close");
              button?.addEventListener("click", () => {
                setCookie("cookies-consent", "true", { path: "/", maxAge: 24 * 60 * 60 });
              });
            }
          }
        });
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  const NextComponent = Component as any;

  const title = "Book top hotels near concerts, festivals and sports";
  const description =
    "We secure top hotel rooms near the action early, so you get prime locations, guaranteed availability, and unbeatable prices.";
  const url = "https://gohoken.com";
  const image = `${process.env.NEXT_PUBLIC_CANONICAL_URL}images/branding/hoken-og-image.png`;
  const logo = `${process.env.NEXT_PUBLIC_CANONICAL_URL}images/branding/hoken-icon-black-favicon.png`;

  return (
    <>
      <SessionProvider session={session}>
        <Head>
          <title>{seo?.title || title}</title>
          <meta name='description' content={seo?.description || description} />
          <meta property='og:title' content={seo?.title || title} />
          <meta property='og:description' content={seo?.description || description} />
          <meta property='og:image' content={seo?.image || image} />
          <meta property='og:type' content='website' />
          <meta property='og:logo' content={logo} />
          <meta property='og:url' content={canonical || url} />
          <meta name='twitter:card' content='summary_large_image' />
          <meta name='twitter:title' content={seo?.title || title} />
          <meta name='twitter:description' content={seo?.description || description} />
          <meta name='twitter:image' content={seo?.image || image} />
          <meta
            name='viewport'
            content='width=device-width, initial-scale=1, maximum-scale=1'
          />
          {canonical ? <link rel='canonical' href={canonical} /> : null}
          {seo?.schema ? (
            <script
              type='application/ld+json'
              dangerouslySetInnerHTML={{ __html: seo.schema }}
            />
          ) : null}
          <meta name='theme-color' content='#000000'></meta>
        </Head>
        <Script id='google-tag-manager' strategy='afterInteractive'>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            ${GTM_SCRIPT_ENV_URL};f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
        `}
        </Script>
        <Script async src='https://www.googletagmanager.com/gtag/js?id=DC-12963600' />
        <Script id='google-tag-analytics' strategy='afterInteractive'>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'DC-12963600');
          `}
        </Script>
        {/* TrustBox script */}
        <Script
          async
          src='//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
        />
        {/* End TrustBox script */}

        <NextAuthenticationManager fallback={fallback}>
          <NextComponent {...pageProps} />
        </NextAuthenticationManager>
      </SessionProvider>
      <Analytics />
    </>
  );
};

export default App;
